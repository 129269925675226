.management-restock {
  box-sizing: border-box;
  * {
    font-family: 見出ゴMB1, "Noto Sans JP", Poppins, "Hiragino Kaku Gothic ProN",
      Meiryo, sans-serif;
  }
  h2 {
    font-size: 30px;
    text-align: center;
    margin: 1em;
    color: #000;
  }
  .period {
    margin-bottom: 2em;
    .graph {
      display: grid;
      grid-template-columns: repeat(4, auto);
      align-items: flex-start;
      margin-bottom: 1em;
      //
      ul {
        display: grid;
        grid-template-columns: subgrid;
        grid-template-rows: subgrid;
        background-color: #fff;
        &:nth-child(2n) {
          background-color: #dbeeff;
        }
        &.calc_total_list {
          background-color: #fff86f;
          text-align: right;
        }
        li {
          display: grid;
          align-items: center;
          gap: 0.5em;
          font-size: 11px;
          border: 1px solid #555;
          border-width: 0 0 1px 0;
          padding: 5px 0.5em;
          color: #000 !important;
          textarea {
            border-color: #d5d5d5;
            border-radius: 5px;
            grid-column: span 4;
            width: 95%;
            border: 1px solid #d5d5d5;
            background-color: #ffffff;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 0.1em 0.5em;
            font-size: 10px;
            height: 2em;
          }
          &:first-child {
            background-color: #134c9a;
            color: #fff !important;
            justify-content: center;
          }
          button {
            background-color: #2185d0;
            text-shadow: none;
            cursor: pointer;
            display: inline-block;
            outline: 0;
            border: none;
            padding: 0.5em 1em;
            font-weight: 700;
            text-align: center;
            border-radius: 0.25em;
            &[disabled] {
              background-color: #ccc;
              cursor: initial;
            }
          }
          span {
            color: #000 !important;
            font-size: 11px;
          }
        }
        li:nth-child(1) {
          font-size: 11px;
        }
      }
      &.user-list {
        grid-template-columns: repeat(2, auto);
      }
    }
  }
  .user-list-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 1em;
    .toggle {
      display: flex;
      justify-content: flex-start;
      color: #fff !important;
      span {
        margin-right: 5px;
        font-size: 10px;
      }
    }
    .button-wrap {
      display: flex;
      justify-content: flex-end;
      button {
        background-color: #2185d0;
        text-shadow: none;
        cursor: pointer;
        display: inline-block;
        outline: 0;
        border: none;
        padding: 0.5em 1em;
        font-weight: 700;
        text-align: center;
        border-radius: 0.25em;
        &[disabled] {
          background-color: #ccc;
          cursor: initial;
        }
      }
    }
  }
}
