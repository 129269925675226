body {
  &.mypage-banner-list {
    .inner {
      max-width: 100%;
      width: 94%;
      height: 94%;
      display: grid;
      grid-template-columns: 100%;
      gap: 20px;
    }
    /* Layout */
    .container {
      min-height: 100vh;
      padding: 2rem;
    }

    .content-wrapper {
      max-width: 1200px;
      margin: 0 auto;
    }

    /* Header */
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
    }

    .page-title {
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
    }

    .create-button {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      background-color: #2185d0;
      color: white;
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      font-size: 0.875rem;
      transition: background-color 0.2s;
    }

    .create-button:hover {
      background-color: #1d76ba;
    }

    .create-button svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }

    /* Banner Cards */
    .banner-grid {
      display: grid;
      gap: 2rem;
    }

    .banner-card {
      background-color: #32373b;
      padding: 1em 2vw;
      box-sizing: border-box;
      border-radius: 10px;
      margin-top: 2em;
      box-shadow: 3px 3px 10px #05071963;
    }

    .banner-content {
      padding: 1.5rem;
    }

    .banner-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1rem;
    }

    .banner-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: #fff;
    }

    .delete-button {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
      color: #dc2626 !important;
      background: transparent;
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
    }

    .delete-button:hover {
      background-color: rgba(220, 38, 38, 0.1);
    }

    .delete-button svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    /* Form Elements */
    .form-group {
      margin-bottom: 1.5rem;
    }

    .form-label {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      color: #fff;
      margin-bottom: 0.5rem;
    }

    .image-upload {
      position: relative;
    }

    .image-preview {
      position: relative;
      display: inline-block;
    }

    .image-preview img {
      max-width: 200px;
      border-radius: 0.5rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .image-overlay {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0);
      border-radius: 0.5rem;
      transition: background-color 0.2s;
    }

    .image-preview:hover .image-overlay {
      background: rgba(0, 0, 0, 0.4);
    }

    .upload-label {
      display: none;
      align-items: center;
      padding: 0.5rem 1rem;
      background: #000;
      border-radius: 0.5rem;
      cursor: pointer;
      color: #fff;
    }

    .image-preview:hover .upload-label {
      display: flex;
    }

    .upload-label svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }

    .empty-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 12rem;
      border: 2px dashed #4a5568;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: border-color 0.2s;
    }

    .empty-upload:hover {
      border-color: #718096;
    }

    .empty-upload-content {
      text-align: center;
    }

    .empty-upload-content svg {
      width: 3rem;
      height: 3rem;
      color: #4a5568;
      margin: 0 auto;
    }

    .empty-upload-text {
      margin-top: 0.5rem;
      font-size: 0.875rem;
      color: #718096;
    }

    .file-input {
      display: none;
    }

    .submit-button {
      padding: 0.75rem 1.5rem;
      background-color: #2185d0;
      color: white;
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      font-weight: 500;
      transition: background-color 0.2s;
    }

    .submit-button:hover {
      background-color: #1d76ba;
    }

    /* Loading Overlay */
    .loading-overlay {
      position: fixed;
      inset: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }

    .loading-spinner {
      width: 2rem;
      height: 2rem;
      color: white;
      animation: spin 1s linear infinite;
    }

    /* Modal */
    .modal-overlay {
      position: fixed;
      inset: 0;
      background: rgba(0, 0, 0, 0.75);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 500;
    }

    .modal-content {
      background: #32373b;
      border-radius: 1rem;
      width: 90%;
      max-width: 600px;
      position: relative;
    }

    .modal-header {
      padding: 1.5rem;
      border-bottom: 1px solid #4a5568;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .modal-header h2 {
      font-size: 1.5rem;
      color: #fff;
      margin: 0;
    }

    .modal-close {
      background: transparent;
      border: none;
      color: #718096;
      cursor: pointer;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: color 0.2s;
    }

    .modal-close:hover {
      color: #fff;
    }

    .modal-body {
      padding: 1.5rem;
    }

    .modal-footer {
      padding: 1.5rem;
      border-top: 1px solid #4a5568;
      display: flex;
      justify-content: flex-end;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    /* Responsive Design */
    @media (max-width: 768px) {
      .container {
        padding: 1rem;
      }

      .image-preview img {
        max-width: 100%;
      }

      .modal-content {
        width: 95%;
        margin: 1rem;
      }
    }
  }
  &.mypage-menu {
    .inner {
      max-width: 1280px;
      width: 94%;
      height: 94%;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 20px;
      .left-pane {
        .menu-large-category {
          margin-bottom: 8em;
        }
        .menu-small-category {
          margin-left: 2em;
          margin-bottom: 1.5em;
        }
        .menu-item-node-small,
        .menu-item-node-link {
          border: solid 1px #ccc;
          padding: 1.2em 0.5em;
          border-radius: 5px;
        }
        .menu-link {
          margin-left: 2em;
        }
        .menu-item-node {
          cursor: pointer;
          margin-bottom: 2em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.menu-item-node-large {
            margin-bottom: 2em;
            span {
              font-size: 24px;
            }
          }

          .badge {
            background-color: #ff862a;
            border-radius: 10px;
            padding: 0.2em 0.5em;
            font-size: 12px;
            margin-left: 0.5em;
          }
          .toggle-button {
            background: transparent;
            border: none;
            cursor: pointer;
            font-size: 10px;
          }
          .menu-box {
            display: flex;
            align-items: center;
          }
          .icon-box {
            display: flex;
            margin-left: 1em;
            .plus-icon,
            .edit-icon {
              display: flex;
              align-items: center;
              margin-right: 0;
              margin-left: 0.5em;
            }
          }
        }
      }
      .right-pane {
        .delete-column {
          margin-top: 1.5em;
          .column {
            display: flex;
            justify-content: end;
            button {
              font-size: 0.8em;
            }
          }
        }
        .content-header {
          height: 50px;
        }
      }
    }
  }
  &.mypage-chat {
    .inner {
      margin: 0;
      width: 95%;
      max-width: 1280px;
      .scroll-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 0;
      }
      .chat-container {
        display: flex;
        height: 90vh;
        background-color: #2c2c2c;
      }

      /* User List Sidebar */
      .sidebar-container {
        width: 300px;
        background-color: #222222;
        border-right: 1px solid #3a3a3a;
        overflow-y: auto;
        flex-shrink: 0;
        scrollbar-width: none;
      }

      .sidebar-header {
        padding: 12px;
        background-color: #222222;
        border-bottom: 1px solid #3a3a3a;
        position: sticky;
        top: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .sidebar-header select {
        width: 100%;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: white;
        font-size: 14px;
      }

      .search-wrapper {
        display: flex;
        gap: 8px;
      }

      .search-wrapper input {
        flex: 1;
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
      }

      .search-wrapper button {
        padding: 8px 12px;
        background-color: #00c300;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
      }

      .search-wrapper button:hover {
        background-color: #00a000;
      }

      .user-list {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .user-item {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        border-bottom: 1px solid #3a3a3a;
        cursor: pointer;
        transition: background-color 0.2s;
      }

      .user-item:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }

      .user-item.active {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #555;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: white;
      }

      .user-avatar-img {
        margin: 0;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #555;
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          color: white;
        }
      }

      .user-info {
        flex: 1;
      }

      .user-name {
        font-weight: bold;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .user-source {
        font-size: 12px;
        color: #a0a0a0;
      }

      .unread {
        color: #00c300 !important;
        margin-left: 8px;
        font-size: 25px;
      }

      /* Chat Area */
      .chat-area {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #2c2c2c;
      }

      .chat-header {
        padding: 16px;
        border-bottom: 1px solid #3a3a3a;
        font-weight: bold;
        display: flex; /* ここがポイント */
        align-items: center;
        justify-content: space-between; /* 左右に分散 */
        p {
          margin-bottom: 0;
          font-size: 18px;
        }
      }

      .status-btn {
        padding: 6px 12px;
        border: none;
        border-radius: 15px;
        background-color: unset;
        border: 1px solid #c3c3c3;
        color: #c3c3c3 !important;
        cursor: pointer;
        transition: background-color 0.2s;
        margin: 0 3px;
      }

      .status-btn:hover {
        background-color: #555;
      }

      /* status-btn.active はアクティブ状態の色を変える */
      .status-btn.active {
        &.required {
          background-color: #00c300;
          border: 1px solid #00c300;
          color: #fff !important;
        }
        &.completed {
          background-color: #157edf;
          border: 1px solid #157edf;
          color: #fff !important;
        }
      }

      /* サイドバーのステータスラベル */
      .user-status-badge {
        margin-left: 8px;
        padding: 2px 6px;
        border-radius: 15px;
        font-size: 11px;
        &.required {
          background-color: unset;
          border: 1px solid #00c300;
          color: #00c300 !important;
          font-weight: bold;
        }
        &.completed {
          background-color: unset;
          border: 1px solid #157edf;
          color: #157edf !important;
          font-weight: bold;
        }
      }

      .chat-messages {
        flex: 1;
        padding: 16px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
      }

      .message-list {
        display: contents;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .message-item {
        margin-bottom: 16px;
        max-width: 70%;
        display: flex;
        flex-direction: column;
      }

      .message-item.admin {
        align-self: flex-end;
      }

      .message-item.user {
        align-self: flex-start;
      }

      .message-bubble {
        padding: 10px 14px;
        border-radius: 18px;
        position: relative;
        word-break: break-word;
        white-space: pre-wrap;
        a {
          text-decoration: underline;
        }
      }

      .message-item.admin .message-bubble {
        background-color: #157edf;
        border-bottom-right-radius: 1px;
        align-self: flex-end;
        &.deleted {
          background-color: #484848;
          color: #8f8f8f;
        }
      }

      .message-item.user .message-bubble {
        background-color: #717171;
        border-bottom-left-radius: 1px;
        align-self: flex-start;
        &.deleted {
          background-color: #484848;
          color: #8f8f8f;
        }
      }

      .message-time {
        font-size: 11px;
        color: #a0a0a0;
        align-self: flex-end;
      }

      .message-item.admin .message-time {
        align-self: end;
        margin-right: 4px;
      }

      .message-item.user .message-time {
        align-self: start;
        margin-left: 4px;
      }

      .message-actions {
        margin-top: 4px;
        display: flex;
        justify-content: flex-end;
      }

      .unsend-button {
        background-color: transparent;
        border: none;
        color: #a0a0a0 !important;
        font-size: 12px;
        cursor: pointer;
        padding: 4px 8px;
        border-radius: 4px;
      }

      .unsend-button:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .message-deleted {
        font-style: italic;
        color: #ff3b30;
        font-size: 12px;
        margin-top: 4px;
        &.admin {
          align-self: self-end;
        }
        &.user {
          align-self: self-start;
        }
      }

      /* Chat Input Area */
      .chat-input {
        padding: 16px;
        border-top: 1px solid #3a3a3a;
        display: flex;
        flex-direction: column;
      }

      .chat-textarea {
        background-color: #3a3a3a;
        border: none;
        border-radius: 10px;
        padding: 12px 16px;
        color: #ffffff;
        resize: none;
        margin-bottom: 8px;
        min-height: 60px;
        font-size: 15px;
      }

      .chat-textarea:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 195, 0, 0.3);
      }

      .chat-controls {
        display: flex;
        justify-content: flex-end;
      }

      .send-button {
        background-color: #00c300;
        color: white;
        border: none;
        border-radius: 20px;
        padding: 8px 20px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.2s;
      }

      .send-button:hover {
        background-color: #00a000;
      }

      .send-button:disabled {
        background-color: #555;
        cursor: not-allowed;
      }

      /* Empty state */
      .empty-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #a0a0a0;
        text-align: center;
        padding: 0 20px;
      }

      .empty-state-icon {
        font-size: 48px;
        margin-bottom: 16px;
      }

      .empty-state-text {
        font-size: 16px;
      }
      .incoming-notice {
        background-color: #ffffff38;
        color: #f9f9f9;
        padding: 8px 12px;
        cursor: pointer;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }

      .incoming-notice:hover {
        background-color: #ffffff62;
      }

      .incoming-notice .arrow {
        margin-right: 6px;
        color: #eb6666;
        font-weight: bold;
      }

      /* モーダルのオーバーレイ */
      .media-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9999; /* 最前面 */
        display: flex;
        align-items: center;
        justify-content: center;
      }

      /* モーダルの中身 */
      .media-modal-content {
        max-width: 90%;
        max-height: 90%;
        /* background-color: #333; */
        padding: 16px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-end; /* 右上に閉じるボタン */
      }

      /* 閉じるボタン */
      .media-modal-close {
        position: absolute;
        top: 15px;
        right: 15px;
        background: unset;
        border: none;
        font-size: 40px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 4px;
      }

      /* 画像拡大表示 */
      .media-modal-img {
        max-width: 100%;
        max-height: 80vh; /* 画面80%まで */
        margin: 0 auto;
        display: block;
      }

      /* 動画拡大表示 */
      .media-modal-video {
        max-width: 100%;
        max-height: 80vh;
        margin: 0 auto;
        display: block;
      }

      /* ダウンロードボタン */
      .media-modal-download {
        position: absolute;
        margin-top: 8px;
        padding: 6px 12px;
        background-color: #636363;
        text-decoration: none;
        border-radius: 4px;
        font-weight: bold;
        bottom: 15px;
        right: 15px;
      }

      .media-modal-download:hover {
        background-color: #a4a4a4;
      }

      /* 画像/動画のサムネイルを小さく調整 (任意) */
      .image-bubble img {
        max-width: 200px;
        border-radius: 8px;
        cursor: pointer;
      }
      .video-bubble video {
        max-width: 200px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }

  &.mypage-broadcast-message-list {
    color: #ffffff;
    .inner {
      max-width: 1280px;
      width: 94%;
      height: 94%;
      display: grid;
      grid-template-columns: 100%;
      gap: 20px;
    }
    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .page-title {
        color: #ffffff !important;
        margin: 0;
      }

      .create-button {
        background-color: #2185d0 !important;
        color: #ffffff !important;
      }
    }
    .broadcast-message-list {
      .filter-section {
        margin-bottom: 20px;
        background-color: #32373a !important;
        padding: 1em 2vw;
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 2em;
        box-shadow: 3px 3px 10px #05071963;

        .filter-controls {
          display: flex;
          gap: 15px;

          .status-filter {
            width: 200px;

            .dropdown {
              background-color: #1b1c1d !important;
              color: #ffffff !important;
              border: 1px solid rgba(255, 255, 255, 0.1) !important;
            }
          }
        }
      }

      .message-table {
        background-color: #2d2d2d !important;
        color: #ffffff !important;
        /* border: 1px solid rgba(255, 255, 255, 0.1) !important; */
        box-shadow: 3px 3px 10px #05071963;

        /* padding: 1em 2vw; */
        /* box-sizing: border-box; */
        border-radius: 10px !important;
        /* margin-top: 2em; */

        th {
          background-color: #1b1c1d !important;
          color: #ffffff !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
        }

        td {
          border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
        }

        .message-body {
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .action-buttons {
          display: flex;
          gap: 5px;

          .edit-button {
            background-color: #6e6e6e !important;
            color: #ffffff !important;
          }

          .delete-button {
            background-color: rgba(219, 40, 40, 0.2) !important;
            color: #db2828 !important;
          }
        }

        .status-label {
          display: inline-block;
          padding: 3px 8px;
          border-radius: 4px;
          font-size: 0.85em;

          &.sent {
            background-color: rgba(33, 186, 69, 0.2);
            color: #21ba45;
          }

          &.scheduled {
            background-color: rgba(33, 133, 208, 0.2);
            color: #2185d0;
          }

          &.pending {
            background-color: rgba(208, 33, 62, 0.2);
            color: #d02133;
          }
        }
      }

      .pagination-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .ui.pagination.menu {
          background-color: #2d2d2d !important;
          border: 1px solid rgba(255, 255, 255, 0.1) !important;

          .item {
            color: #ffffff !important;

            &.active {
              background-color: #2185d0 !important;
            }
          }
        }
      }
    }
    .preview-modal,
    .confirm-modal,
    .discard-modal,
    .delete-modal {
      background-color: #2d2d2d !important;

      .header {
        background-color: #1b1c1d !important;
        color: #ffffff !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .content {
        color: #ffffff !important;
        background: #2d2d2d;

        .message-modal-preview {
          background-color: #222222;
          border-radius: 10px;
          padding: 15px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .app-icon {
            width: 48px;
            height: 48px;
            background-color: #2185d0;
            border-radius: 8px;
            flex-shrink: 0;
          }

          .message-content {
            flex: 1;

            .message-header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;

              .app-name {
                font-weight: bold;
                font-size: 14px;
                color: #ffffff;
              }

              .message-time {
                font-size: 14px;
                color: #aaaaaa;
              }
            }

            .message-title {
              font-weight: bold;
              margin-bottom: 5px;
              font-size: 16px;
              color: #ffffff;
            }

            .message-body {
              font-size: 14px;
              color: #ffffff;
              word-break: break-word;
            }
          }
        }

        .message-details {
          p {
            margin-bottom: 8px;
          }
        }
      }

      .actions {
        background-color: #1b1c1d !important;
        border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
  }
  &.mypage-broadcast-message-edit {
    color: #ffffff;
    .inner {
      max-width: 1280px;
      width: 94%;
      height: 94%;
      display: grid;
      grid-template-columns: 100%;
      gap: 20px;
    }
    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .page-title {
        color: #ffffff !important;
        margin: 0;
      }

      .create-button {
        background-color: #2185d0 !important;
        color: #ffffff !important;
      }
    }
    .broadcast-message-edit {
      .required {
        color: #db2828;
      }

      .form-segment {
        background-color: #32373b;
        padding: 1em 2vw;
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 2em;
        box-shadow: 3px 3px 10px #05071963;

        label {
          color: #ffffff !important;
        }
      }

      .dark-input input,
      .dark-textarea textarea {
        background-color: transparent !important;
        color: #ffffff !important;
        border: 1px solid #777 !important;

        &::placeholder {
          color: #aaaaaa !important;
        }
      }

      .dark-dropdown {
        .dropdown {
          background-color: transparent !important;
          color: #ffffff !important;
          border: 1px solid rgba(255, 255, 255, 0.1) !important;

          .menu {
            background-color: #1b1c1d !important;
            border: 1px solid rgba(255, 255, 255, 0.1) !important;

            .item {
              color: #ffffff !important;

              &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
              }
            }
          }
        }
      }

      .dark-radio {
        label {
          color: #ffffff !important;
        }
      }

      .dark-datepicker {
        background-color: transparent !important;
        color: #ffffff !important;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .react-datepicker__time-list-item--disabled {
        display: none;
      }

      .studio-checkboxes {
        max-height: 400px;
        overflow-y: auto;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      .prefecture-group {
        margin-bottom: 20px;
      }

      .prefecture-group h4 {
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
      }

      .studio-group {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 10px;
      }

      .dark-checkbox {
        margin-bottom: 8px !important;
      }

      .character-count {
        text-align: right;
        font-size: 0.85em;
        color: #aaaaaa;
        margin-top: 5px;

        .error {
          color: #db2828;
        }
      }

      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        .cancel-button {
          background-color: #6e6e6e !important;
          color: #ffffff !important;
        }

        .save-button {
          background-color: #2185d0 !important;
          color: #ffffff !important;
        }

        .send-button {
          background-color: #21ba45 !important;
          color: #ffffff !important;
        }
      }

      /* プレビュー部分のスタイル */
      .preview-section {
        position: sticky;
        top: 20px;

        .preview-title {
          color: #ffffff !important;
        }

        .device-preview {
          background-color: #2d2d2d !important;
          border: 1px solid rgba(255, 255, 255, 0.1) !important;
          padding: 0;
          overflow: hidden;
          margin: 1rem 0;

          .device-frame {
            background-color: #111111;
            border-radius: 10px;
            overflow: hidden;

            .device-status-bar {
              background-color: #000000;
              color: white;
              padding: 5px 15px;
              font-size: 12px;
              text-align: center;

              .time {
                font-weight: bold;
              }
            }

            .message-preview {
              background-color: #222222;
              margin: 10px;
              border-radius: 10px;
              padding: 12px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
              display: flex;
              gap: 10px;

              .app-icon {
                width: 40px;
                height: 40px;
                background-color: #2185d0;
                border-radius: 8px;
                flex-shrink: 0;
              }

              .message-content {
                flex: 1;

                .message-header {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 5px;

                  .app-name {
                    font-weight: bold;
                    font-size: 12px;
                    color: #ffffff;
                  }

                  .message-time {
                    font-size: 12px;
                    color: #aaaaaa;
                  }
                }

                .message-title {
                  font-weight: bold;
                  margin-bottom: 3px;
                  color: #ffffff;
                }

                .message-body {
                  font-size: 14px;
                  color: #ffffff;
                  word-break: break-word;
                }
              }
            }
          }
        }
      }
    }
    .confirm-modal,
    .discard-modal,
    .delete-modal {
      background-color: #2d2d2d !important;

      .header {
        background-color: #1b1c1d !important;
        color: #ffffff !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .content {
        color: #ffffff !important;
        background: #2d2d2d;
        .message-details {
          p {
            margin-bottom: 8px;
          }
        }
      }

      .actions {
        background-color: #1b1c1d !important;
        border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
    /* 日付ピッカーのスタイル調整 */
    .react-datepicker-wrapper {
      width: 100%;

      .form-control {
        width: 100%;
        padding: 9px 14px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
    }

    /* React DatePickerのカスタマイズ */
    .react-datepicker {
      background-color: #1b1c1d !important;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;

      .react-datepicker__header {
        background-color: #2d2d2d !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .react-datepicker__current-month,
      .react-datepicker__day-name,
      .react-datepicker__day {
        color: #ffffff !important;
      }

      .react-datepicker__day:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }

      .react-datepicker__day--selected {
        background-color: #2185d0 !important;
      }

      .react-datepicker__time-container {
        border-left: 1px solid rgba(255, 255, 255, 0.1) !important;

        .react-datepicker__time {
          background-color: #1b1c1d !important;

          .react-datepicker__time-box {
            ul.react-datepicker__time-list li.react-datepicker__time-list-item {
              color: #ffffff !important;

              &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
              }

              &--selected {
                background-color: #2185d0 !important;
              }
            }
          }
        }
      }
    }
  }

  &.mypage-push-notification-list {
    color: #ffffff;
    .inner {
      max-width: 1280px;
      width: 94%;
      height: 94%;
      display: grid;
      grid-template-columns: 100%;
      gap: 20px;
    }
    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .page-title {
        color: #ffffff !important;
        margin: 0;
      }

      .create-button {
        background-color: #2185d0 !important;
        color: #ffffff !important;
      }
    }
    .push-notification-list {
      .filter-section {
        margin-bottom: 20px;
        background-color: #32373a !important;
        padding: 1em 2vw;
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 2em;
        box-shadow: 3px 3px 10px #05071963;

        .filter-controls {
          display: flex;
          gap: 15px;

          .status-filter {
            width: 200px;

            .dropdown {
              background-color: #1b1c1d !important;
              color: #ffffff !important;
              border: 1px solid rgba(255, 255, 255, 0.1) !important;
            }
          }
        }
      }

      .notification-table {
        background-color: #2d2d2d !important;
        color: #ffffff !important;
        /* border: 1px solid rgba(255, 255, 255, 0.1) !important; */
        box-shadow: 3px 3px 10px #05071963;

        /* padding: 1em 2vw; */
        /* box-sizing: border-box; */
        border-radius: 10px !important;
        /* margin-top: 2em; */

        th {
          background-color: #1b1c1d !important;
          color: #ffffff !important;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
        }

        td {
          border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
        }

        .notification-body {
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .action-buttons {
          display: flex;
          gap: 5px;

          .edit-button {
            background-color: #6e6e6e !important;
            color: #ffffff !important;
          }

          .delete-button {
            background-color: rgba(219, 40, 40, 0.2) !important;
            color: #db2828 !important;
          }
        }

        .status-label {
          display: inline-block;
          padding: 3px 8px;
          border-radius: 4px;
          font-size: 0.85em;

          &.sent {
            background-color: rgba(33, 186, 69, 0.2);
            color: #21ba45;
          }

          &.scheduled {
            background-color: rgba(33, 133, 208, 0.2);
            color: #2185d0;
          }

          &.pending {
            background-color: rgba(208, 33, 62, 0.2);
            color: #d02133;
          }
        }
      }

      .pagination-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .ui.pagination.menu {
          background-color: #2d2d2d !important;
          border: 1px solid rgba(255, 255, 255, 0.1) !important;

          .item {
            color: #ffffff !important;

            &.active {
              background-color: #2185d0 !important;
            }
          }
        }
      }
    }
    .preview-modal,
    .confirm-modal,
    .discard-modal,
    .delete-modal {
      background-color: #2d2d2d !important;

      .header {
        background-color: #1b1c1d !important;
        color: #ffffff !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .content {
        color: #ffffff !important;
        background: #2d2d2d;

        .notification-modal-preview {
          background-color: #222222;
          border-radius: 10px;
          padding: 15px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .app-icon {
            width: 48px;
            height: 48px;
            background-color: #2185d0;
            border-radius: 8px;
            flex-shrink: 0;
          }

          .notification-content {
            flex: 1;

            .notification-header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;

              .app-name {
                font-weight: bold;
                font-size: 14px;
                color: #ffffff;
              }

              .notification-time {
                font-size: 14px;
                color: #aaaaaa;
              }
            }

            .notification-title {
              font-weight: bold;
              margin-bottom: 5px;
              font-size: 16px;
              color: #ffffff;
            }

            .notification-body {
              font-size: 14px;
              color: #ffffff;
              word-break: break-word;
            }
          }
        }

        .notification-details {
          p {
            margin-bottom: 8px;
          }
        }
      }

      .actions {
        background-color: #1b1c1d !important;
        border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
  }
  &.mypage-push-notification-edit {
    color: #ffffff;
    .inner {
      max-width: 1280px;
      width: 94%;
      height: 94%;
      display: grid;
      grid-template-columns: 100%;
      gap: 20px;
    }
    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .page-title {
        color: #ffffff !important;
        margin: 0;
      }

      .create-button {
        background-color: #2185d0 !important;
        color: #ffffff !important;
      }
    }
    .push-notification-edit {
      .required {
        color: #db2828;
      }

      .form-segment {
        background-color: #32373b;
        padding: 1em 2vw;
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 2em;
        box-shadow: 3px 3px 10px #05071963;

        label {
          color: #ffffff !important;
        }
      }

      .dark-input input,
      .dark-textarea textarea {
        background-color: transparent !important;
        color: #ffffff !important;
        border: 1px solid #777 !important;

        &::placeholder {
          color: #aaaaaa !important;
        }
      }

      .dark-dropdown {
        .dropdown {
          background-color: transparent !important;
          color: #ffffff !important;
          border: 1px solid rgba(255, 255, 255, 0.1) !important;

          .menu {
            background-color: #1b1c1d !important;
            border: 1px solid rgba(255, 255, 255, 0.1) !important;

            .item {
              color: #ffffff !important;

              &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
              }
            }
          }
        }
      }

      .dark-radio {
        label {
          color: #ffffff !important;
        }
      }

      .dark-datepicker {
        background-color: transparent !important;
        color: #ffffff !important;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .react-datepicker__time-list-item--disabled {
        display: none;
      }

      .studio-checkboxes {
        max-height: 400px;
        overflow-y: auto;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      .prefecture-group {
        margin-bottom: 20px;
      }

      .prefecture-group h4 {
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
      }

      .studio-group {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 10px;
      }

      .dark-checkbox {
        margin-bottom: 8px !important;
      }

      .character-count {
        text-align: right;
        font-size: 0.85em;
        color: #aaaaaa;
        margin-top: 5px;

        .error {
          color: #db2828;
        }
      }

      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        .cancel-button {
          background-color: #6e6e6e !important;
          color: #ffffff !important;
        }

        .preview-button {
          background-color: #6e6e6e !important;
          color: #ffffff !important;
        }

        .save-button {
          background-color: #2185d0 !important;
          color: #ffffff !important;
        }

        .send-button {
          background-color: #21ba45 !important;
          color: #ffffff !important;
        }
      }

      /* プレビュー部分のスタイル */
      .preview-section {
        position: sticky;
        top: 20px;

        .preview-title {
          color: #ffffff !important;
        }

        .device-preview {
          background-color: #2d2d2d !important;
          border: 1px solid rgba(255, 255, 255, 0.1) !important;
          padding: 0;
          overflow: hidden;
          margin: 1rem 0;

          .device-frame {
            background-color: #111111;
            border-radius: 10px;
            overflow: hidden;

            .device-status-bar {
              background-color: #000000;
              color: white;
              padding: 5px 15px;
              font-size: 12px;
              text-align: center;

              .time {
                font-weight: bold;
              }
            }

            .notification-preview {
              background-color: #222222;
              margin: 10px;
              border-radius: 10px;
              padding: 12px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
              display: flex;
              gap: 10px;

              .app-icon {
                width: 40px;
                height: 40px;
                background-color: #2185d0;
                border-radius: 8px;
                flex-shrink: 0;
              }

              .notification-content {
                flex: 1;

                .notification-header {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 5px;

                  .app-name {
                    font-weight: bold;
                    font-size: 12px;
                    color: #ffffff;
                  }

                  .notification-time {
                    font-size: 12px;
                    color: #aaaaaa;
                  }
                }

                .notification-title {
                  font-weight: bold;
                  margin-bottom: 3px;
                  color: #ffffff;
                }

                .notification-body {
                  font-size: 14px;
                  color: #ffffff;
                  word-break: break-word;
                }
              }
            }
          }
        }
      }
    }
    .preview-modal,
    .confirm-modal,
    .discard-modal,
    .delete-modal {
      background-color: #2d2d2d !important;

      .header {
        background-color: #1b1c1d !important;
        color: #ffffff !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .content {
        color: #ffffff !important;
        background: #2d2d2d;

        .notification-modal-preview {
          background-color: #222222;
          border-radius: 10px;
          padding: 15px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .app-icon {
            width: 48px;
            height: 48px;
            background-color: #2185d0;
            border-radius: 8px;
            flex-shrink: 0;
          }

          .notification-content {
            flex: 1;

            .notification-header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;

              .app-name {
                font-weight: bold;
                font-size: 14px;
                color: #ffffff;
              }

              .notification-time {
                font-size: 14px;
                color: #aaaaaa;
              }
            }

            .notification-title {
              font-weight: bold;
              margin-bottom: 5px;
              font-size: 16px;
              color: #ffffff;
            }

            .notification-body {
              font-size: 14px;
              color: #ffffff;
              word-break: break-word;
            }
          }
        }

        .notification-details {
          p {
            margin-bottom: 8px;
          }
        }
      }

      .actions {
        background-color: #1b1c1d !important;
        border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
      }
    }
    /* 日付ピッカーのスタイル調整 */
    .react-datepicker-wrapper {
      width: 100%;

      .form-control {
        width: 100%;
        padding: 9px 14px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
    }

    /* React DatePickerのカスタマイズ */
    .react-datepicker {
      background-color: #1b1c1d !important;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;

      .react-datepicker__header {
        background-color: #2d2d2d !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
      }

      .react-datepicker__current-month,
      .react-datepicker__day-name,
      .react-datepicker__day {
        color: #ffffff !important;
      }

      .react-datepicker__day:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }

      .react-datepicker__day--selected {
        background-color: #2185d0 !important;
      }

      .react-datepicker__time-container {
        border-left: 1px solid rgba(255, 255, 255, 0.1) !important;

        .react-datepicker__time {
          background-color: #1b1c1d !important;

          .react-datepicker__time-box {
            ul.react-datepicker__time-list li.react-datepicker__time-list-item {
              color: #ffffff !important;

              &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
              }

              &--selected {
                background-color: #2185d0 !important;
              }
            }
          }
        }
      }
    }
  }
}
