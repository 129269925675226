:root {
  --header-height: 3.5rem;
}
ul,
li {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
p,
li,
span,
button,
i,
label,
small,
a {
  color: #ffffff !important;
  font-size: clamp(10px, 2vw, 15px);
}

.image {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  img,
  video {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-position: auto;
  }
}

body {
  background-color: #23282c !important;
  /* ヘッダー */
  .top {
    background-color: #32373b !important;
    color: #fff !important;
    padding: 0.5rem 1rem !important;
    position: fixed !important;
    width: 100% !important;
    height: var(--header-height);
    z-index: 11 !important;
    top: 0 !important;
    left: 0 !important;
    box-sizing: border-box !important;
    margin: 0 !important;
  }

  main {
    display: grid !important;
    grid-template-columns: 180px auto;
    min-height: 100vh;
    padding-top: var(--header-height);
    /************************/
    /* 共通部分 */
    /************************/
    /* サイドバー */
    .sidebar {
      background-color: #32373b;
      display: grid;
      grid-template-columns: 100%;
      align-items: center;
      align-content: flex-start;
      a,
      .accordion-item {
        display: grid;
        grid-template-columns: 30px auto;
        padding: 1em;
        position: relative;
        overflow: hidden;
        &:hover {
          font-weight: bold;
          &:before {
            content: "";
            display: block;
            width: 8px;
            height: 500px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #3d82c7;
          }
        }
        &.on {
          background-color: #2b5c8e;
          font-weight: bold;
          color: #3d82c7;
          &:before {
            content: "";
            display: block;
            width: 8px;
            height: 500px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #3d82c7;
          }
        }
        i {
          font-size: 18px;
        }
        span {
          font-size: 12px;
          color: #3d82c7;
        }
      }
      /* アコーディオン */
      ul.children {
        background-color: #434a50;
        height: 0px;
        overflow: hidden;
        transition-duration: 0.5s;
        a {
          display: grid;
          grid-template-columns: 100%;
          list-style: none;
          li {
            font-size: 12px;
          }
        }
      }
    }
    .inner {
      width: 80%;
      margin: 25px auto 25px 25px;
      max-width: 1280px;
      section {
        background-color: #32373b;
        padding: 1em 2vw;
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 2em;
        box-shadow: 3px 3px 10px #05071963;
      }
    }
  }
}
