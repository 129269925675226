body {
  /* 生徒一覧 */
  &.management-customer-list {
    .customer-header {
      background-color: #7b7b7b;
    }
    .customer-grid {
      display: grid;
      grid-template-columns: 5% 10% 10% 10% 5% 10% 20% 10% 10% 10%;
      grid-template-rows: 2.75em;
      margin: 0;
      transition-duration: 0.25s;
      &:hover {
        background-color: #00000010;
      }
      .cell {
        list-style: none;
        font-size: 11px;
        height: auto !important;
        overflow: hidden;
        border-bottom: 1px solid #ddd;
        display: grid;
        align-items: center;
      }
      &.still {
        li {
          color: #d8c437 !important;
        }
      }
    }
    .card {
      i {
        color: #505050 !important;
      }
      .content {
        .invoice-item {
          color: #000 !important;
        }
        p {
          color: #000 !important;
        }
        input {
          color: #000;
        }
        input:focus {
          color: #000;
        }
      }
    }
  }
  /* 体験者一覧 */
  &.management-free-customer-list {
    .inner {
      width: 95%;
      display: inline-grid;
      max-width: 96%;
      .studio_btn {
        height: fit-content;
        overflow-x: scroll;
        margin-bottom: 1em;
        display: flex;
        flex-wrap: wrap;
        button {
          background: #808080;
          font-size: 12px;
          font-weight: bold;
          border-radius: 3px;
          padding: 0.5em 1em;
          color: #45535a;
        }
        &.studio_btn_week {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          column-gap: 2px;
          .column {
            border: 1px solid #646464;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 3px;
            column-gap: 3px;
            padding: 2px;
            align-content: baseline;
            white-space: nowrap;
          }
          .ttl {
            background-color: #d9d9d9;
            font-size: 10px;
            font-weight: bold;
            text-align: center;
            grid-area: 1 / 1 / 2 / 3;
          }
          a {
            button {
              width: 100%;
              margin: 0;
              font-size: 10px;
            }
          }
        }
      }
      .list-container {
        overflow-x: scroll;
      }
      .customer-header {
        background-color: #808080;
      }
      .customer-grid {
        display: grid;
        grid-template-columns: 3% 8% 3% 6% 5% 18% 6% 14% 5% 7% 4% auto 6%;
        grid-template-rows: 2.75em;
        margin: 0;
        transition-duration: 0.25s;
        min-width: 1280px;
        &:hover {
          background-color: #00000010;
        }
        .cell {
          list-style: none;
          font-size: 11px;
          height: auto !important;
          overflow: hidden;
          border-bottom: 1px solid #646464;
          display: grid;
          align-items: center;
          white-space: nowrap;
          overflow-x: auto;
          border-right: 1px solid #646464;
          text-align: center;
          button {
            padding: 7px;
            margin: 0 auto;
            font-size: 10px;
          }
        }
      }
      .overlayWrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        z-index: 12;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .overlayContent {
          z-index: 13;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          label {
            color: #fff;
            font-weight: bold;
            text-align: center;
          }
          input {
            height: 35px;
            width: 150px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  /* メール送信 */
  &.management-send-mail {
    .inner {
      max-width: 100%;
      width: 94%;
      height: 94%;
      display: grid;
      grid-template-columns: 35% 40% 25%;
      gap: 20px;
      .calendar {
        /* background-color: rgb(58, 66, 63); */
        /* FullCalendar全体のフォントサイズを調整 */
        .fc {
          width: 100%;
          height: 100%;
        }
        /* ヘッダー部分のスタイルを調整 */
        .fc-toolbar h2 {
          font-size: 1em; /* ヘッダータイトルのサイズ */
        }
        .fc-button {
          font-size: 0.8em; /* ボタンのサイズ */
        }
        .selected-date {
          background-color: #3788d8;
        }
      }
      .tempBtn {
        text-align-last: end;
      }
      .buttonWrap {
        button {
          background-color: #7e7e7e;
          font-size: 12px;
          margin-bottom: 5px;
          &.on {
            background-color: #4c8fd2;
          }
        }
      }
      .alert {
        margin-bottom: 10px;
        p {
          font-size: 12px;
          font-weight: bold;
        }
      }
      textarea {
        font-size: 12px;
        width: 100%;
        background-color: transparent;
        color: #fff;
        border: solid 1px #777;
        padding: 10px;
        border-radius: 5px;
      }
      .sendBtn {
        text-align: center;
      }
      .send-list {
        p {
          font-size: 12px;
        }
      }
      .overlayWrap {
        background-color: #00000080;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 12;
        .overlayContent1 {
          display: grid;
          grid-template-columns: 20% 80%;
          grid-template-rows: 8% auto 8%;
          width: 80%;
          height: 80%;
          max-width: 1200px;
          padding: 15px 30px;
          background-color: #545454;
          position: fixed;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          box-shadow: 0 0 30px #353535;
          border-radius: 10px;
          z-index: 9999;
          .ovHeader {
            grid-column: 1 / 3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .deleteBtn {
              background-color: #cf3333;
            }
          }
          .templateList {
            overflow-y: scroll;
            padding: 8px;
            p {
              cursor: pointer;
              border-bottom: 0.5px solid #8c8c8c;
            }
          }
          .templateDetail {
            overflow-y: scroll;
            padding: 20px;
          }
          .btnArea {
            grid-column: 2 /3;
            text-align-last: center;
          }
        }
        .overlayContent2 {
          width: 80%;
          height: 80%;
          max-width: 1200px;
          padding: 15px 30px;
          background-color: #545454;
          position: fixed;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          box-shadow: 0 0 30px #353535;
          border-radius: 10px;
          .btnArea {
            grid-column: 2 /3;
            text-align-last: center;
          }
        }
      }
    }
  }
  /* アクセスアンケート */
  &.management-access-questionnaire {
    .inner {
      max-width: none;
      width: calc(100%);
      box-sizing: border-box;
      padding: 20px;
      overflow: auto;

      ul.accses-studio-menu {
        display: grid;
        grid-template-columns: repeat(auto-fill, 8em);
        box-sizing: border-box;
        gap: 0.25em;
        padding: px;
        margin: px;

        position: sticky;
        top: 60px;
        li {
          list-style: none;
          border: 1px solid #fff;
          border-radius: 3px;
          transition-duration: 0.25s;
          &:hover {
            background-color: #ffffff80;
          }
        }
      }

      .chart-area {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        margin-bottom: 10px;
      }

      .chart-wrapper {
        flex: 0 0 auto;
        min-width: 300px;
        margin: 0px 5px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        height: 250px;
      }
    }
  }
}
