.management-recital-2025-special-fee {
  box-sizing: border-box;
  * {
    font-family: 見出ゴMB1, "Noto Sans JP", Poppins, "Hiragino Kaku Gothic ProN",
      Meiryo, sans-serif;
  }
  h2 {
    font-size: 30px;
    text-align: center;
    margin: 1em;
    color: #000;
  }
  .graph {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-template-rows: repeat(30, auto);
    align-items: flex-start;
    //
    ul {
      display: grid;
      grid-template-columns: subgrid;
      grid-template-rows: subgrid;
      grid-row: span 30;
      background-color: #fff;
      &:nth-child(2n) {
        background-color: #dbeeff;
      }
      &.calc_total_list {
        background-color: #fff86f;
      }
      li {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-items: center;
        gap: 0.5em;
        font-size: 11px;
        border: 1px solid #555;
        border-width: 0 0 1px 0;
        padding: 5px 0.5em;
        color: #000 !important;
        textarea {
          border-color: #d5d5d5;
          border-radius: 5px;
          grid-column: span 4;
          width: 95%;
          border: 1px solid #d5d5d5;
          background-color: #ffffff;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 0.1em 0.5em;
          font-size: 10px;
          height: 2em;
        }
        &:first-child {
          background-color: #134c9a;
          color: #fff !important;
          justify-content: center;
        }
      }
      li:nth-child(1) {
        font-size: 11px;
      }
    }
    //
    input[type="tel"] {
      border: 1px solid #d5d5d5;
      background-color: #ffffff;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 0.1em 0.5em;
      font-size: 10px;
      &::placeholder {
        color: #00000040;
      }
      &.price {
        width: 5em;
      }
      &.count {
        width: 2.5em;
      }
    }
    //
    .total {
      font-size: 11px;
      color: #000 !important;
      &:before {
        content: "= ";
      }
    }
  }

  // 保存時のメッセージ
  .save-anime {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #000e43;
    padding: 0.75em 1em;
    font-size: 13px;
    color: #fff;
    border-radius: 0 0 0 5px;
    transition-duration: 0.25s;
    opacity: 0;
    translate: 100% 0;
    z-index: 9999;

    &.on {
      animation: saveAnimation 3s ease forwards;
      @keyframes saveAnimation {
        20% {
          opacity: 1;
          translate: 0 0;
        }
        90% {
          opacity: 1;
          translate: 0 0;
        }
        100% {
          opacity: 0;
          translate: 100% 0;
        }
      }
    }
  }
}
