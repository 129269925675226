body {
  .loading-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      color: #fff;
    }
  }
}
