body {
  /* オーバーレイ */
  .overlay {
    display: none;
    &.open {
      display: block;
    }
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    /* backdrop-filter: blur(8px); */
    z-index: 9998;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    .ov {
      width: 80%;
      height: 90vh;
      max-width: 1200px;
      padding: 30px;
      background-color: #222;
      position: fixed;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      box-shadow: 0 0 30px #353535;
      border-radius: 10px;
      z-index: 9999;
      .cancelBtn {
        position: fixed;
        border-radius: 999px;
        z-index: 99999;
        top: 10px;
        right: 0px;
        font-size: 30px;
        cursor: pointer;
      }
    }
  }
  /* 画像追加用オーバーレイ */
  .grid2 {
    display: grid;
    grid-template-columns: 80% 20%;
    /* 左カラム */
    .imgArea {
      overflow: scroll;
      li {
        position: relative;
        cursor: pointer;
        img {
          transition-duration: 0.25s;
        }
        &:hover {
          img {
            scale: 1.05;
            opacity: 0.7;
          }
        }
        i {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 1;
          font-size: 35px;
          opacity: 0.5;
          transition-duration: 0.25s;
          &.on {
            color: #ecee45 !important;
            opacity: 1;
          }
        }
      }
    }
    /* 右カラム */
    .hashtagData {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      padding: 10px;
      .imgBox {
        display: grid;
        row-gap: 2em;
        width: 100%;
        /* 現在、選択中の画像 */
        figure {
          width: 100%;
          height: 150px;
          overflow: hidden;
          margin: 0 0 1em;
          padding: 0;
          img {
            height: 150px;
            width: auto;
          }
        }
        .hashtagArea {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5em;
          li {
            list-style: none;
            background-color: #fff;
            border-radius: 999px;
            color: #000 !important;
            padding: 0.25em 0.5em;
            font-size: 12px;
            &:before {
              content: "#";
            }
          }
        }
        /* 全ての選択した画像 */
        .selectedImg {
          max-height: 380px;
          overflow-y: auto;

          .imgList {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
      .addImg {
        width: 100%;
      }
    }
  }

  /* 画像のリスト表示 */
  .imgList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    li {
      overflow: hidden;
      position: relative;
      background-color: #fff;
      &:before {
        padding-top: 100%;
        content: "";
        display: block;
      }
      img {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }
      video {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }
      .deleteBtn {
        position: absolute;
        z-index: 999;
        top: 0;
        right: -3px;
        cursor: pointer;
      }
    }
  }
  .loadBtn {
    text-align: center;
    padding: 5px;
  }
  .searchWrap {
    padding: 10px 0;
  }
  /* ビジュアルコンポーネント */
  .visual-component {
    padding: 20px;
    img,
    video {
      cursor: pointer;
    }
    .scroll {
      overflow-y: auto;
      height: 45vh;
    }
    .media-wrap {
      width: 50% !important;
    }
    .hashtag-wrap {
      .label {
        color: #676767 !important;
        margin: 2px;
        .icon {
          color: #aaaaaa !important;
        }
      }
      .button-group {
        margin-bottom: 5px;
        button {
          margin: 0;
          padding: 10px 12px;
        }
      }
      .scrollable-container {
        height: 160px;
        overflow-y: auto;
        padding: 0;
        background: transparent;
        border: none;
      }
    }
  }
  /* sectionのヘッダー */
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 2em 1em 2.5em;
    h3 {
      margin: 0;
    }
    .button-group {
      display: flex;
      align-items: end;
      .ui.form {
        display: flex;
        align-items: center;
        margin-right: 1.5em;
        label {
          text-align: center;
          font-size: 0.8em;
        }
      }
      .ui.button {
        font-size: 0.8em;
      }
    }
  }
  /* フォームのラベル */
  .form-label {
    font-size: 0.8em;
    margin: 0 0 0.3em 0.8em;
  }
  /* テキスト入力 */
  .ui.input {
    width: 100%;
    input {
      background-color: transparent;
      color: #fff;
      border: solid 1px #777;
      &:focus {
        background-color: transparent;
        border-color: #0abab5;
        color: #fff;
      }
      &::selection {
        color: #fff;
      }
    }
  }
  /* セレクトボックス */
  .ui.selection.dropdown {
    background-color: transparent;
    border: solid 1px #777;
    color: #fff;
    width: 100%;
    i {
      display: none;
    }
    .divider.text {
      color: #fff !important;
    }
    .visible.menu.transition {
      background-color: #434a50;
      border: none;
      .item {
        border: none;
      }
    }
    &.visible {
      border-color: #0abab5;
    }
  }
  /* テキストエリア */
  .ui.form textarea {
    background-color: transparent;
    color: #fff;
    border: solid 1px #777;
    &:focus {
      background-color: transparent;
      border-color: #0abab5;
      color: #fff;
    }
    &::selection {
      color: #fff;
    }
  }
  /* 画像一覧 */
  .ui.cards.image-list {
    width: 100%;
    margin: 1em auto 0;
    .ui.card {
      background: transparent;
      box-shadow: none;
      border: none;
    }
  }
  /* スタジオ一覧 */
  &.website-studio-list {
    section {
      display: grid;
      row-gap: 20px;
      grid-template-columns: 100%;
      h4 {
        margin: 0 !important;
      }
      .grid {
        margin: 0;
        .row {
          gap: 8px;
          .button-item {
            width: 176px !important;
            padding: 0 !important;
            button {
              width: 100% !important;
              white-space: nowrap;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  /* スタジオ詳細 */
  &.website-studio-edit {
    .inner {
      max-width: 1280px;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 20px;
      .details {
        padding: 0 1em;
        width: 100% !important;
        ul {
          display: flex;
          justify-content: center;
          padding: 1em auto;
          li {
            list-style: none;
            padding: 0.5em 0;
            &.icon {
              width: 10%;
              display: flex;
              align-items: center;
              .dropdown-wrap {
                display: flex;
                .ui.dropdown .dropdown.icon {
                  display: none;
                }
                img {
                  margin-right: 0.5em;
                  margin-left: 0.5em;
                }
              }
              .menu.transition.visible {
                background-color: #434a50;
                min-width: none !important;
              }
            }
            &.text {
              width: 90%;
              display: flex;
              align-items: center;
              .input-action-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                .details-input {
                  position: relative;
                  flex-grow: 1;
                }
                .delete-details-button,
                .add-details-button {
                  background: none;
                  border: none;
                  box-shadow: none;
                  padding: 0;
                  position: absolute;
                  right: -1em;
                  top: 50%;
                  transform: translateY(-50%);
                  margin-left: 10px;
                  visibility: hidden;
                }
                .add-details-button {
                  margin-right: 1.2em;
                }
                .delete-details-button {
                  margin-right: 2.5em;
                }
              }
              &:hover .action-buttons > button {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  /* インストラクター一覧 */
  &.website-instructor-list {
    section {
      display: grid;
      row-gap: 20px;
      grid-template-columns: 100%;
      h4 {
        margin: 0 !important;
      }
      .grid {
        margin: 0;
        .row {
          gap: 8px;
          .button-item {
            width: 176px !important;
            padding: 0 !important;
            button {
              width: 100% !important;
              white-space: nowrap;
              display: flex;
              justify-content: center;
              span {
                font-size: 12px;
                &:before {
                  content: "";
                  padding-left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  /* インストラクター詳細 */
  &.website-instructor-edit {
    .inner {
      max-width: 1280px;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 20px;
      .youtube {
        width: 100% !important;
        ul {
          display: flex;
          justify-content: center;
          padding: 1em auto;
          li {
            list-style: none;
            padding: 0.5em 0;
            &.youtube-id {
              width: 100%;
              display: flex;
              align-items: center;
              .input-action-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                .youtube-id-input {
                  position: relative;
                  flex-grow: 1;
                }
                .delete-youtube-button,
                .add-youtube-button {
                  background: none;
                  border: none;
                  box-shadow: none;
                  padding: 0;
                  position: absolute;
                  right: -1em;
                  top: 50%;
                  transform: translateY(-50%);
                  margin-left: 10px;
                  visibility: hidden;
                }
                .add-youtube-button {
                  margin-right: 1.2em;
                }
                .delete-youtube-button {
                  margin-right: 2.5em;
                }
              }
              &:hover .action-buttons > button {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  /* レッスン一覧 */
  &.website-lesson-list {
    section {
      display: grid;
      row-gap: 20px;
      grid-template-columns: 100%;
      h4 {
        margin: 0 !important;
      }
      .grid {
        margin: 0;
        .row {
          gap: 8px;
          .button-item {
            width: 176px !important;
            padding: 0 !important;
            button {
              width: 100% !important;
              white-space: nowrap;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  /* レッスン詳細 */
  &.website-lesson-edit {
    .ov {
      width: 500px;
      height: 200px;
      background-color: #32373b;
      ul {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 1em;
        li {
          list-style: none;
          padding-bottom: 1em;
        }
      }
    }
    .inner {
      max-width: 1280px;
      display: grid;
      grid-template-columns: 40% 60%;
      gap: 20px;
      .fc-daygrid-day {
        &.on {
          background-color: #2b5c8e;
        }
        .fc-daygrid-day-top {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .fc-daygrid-day-number {
            font-size: clamp(12px, 5vw, 30px);
          }
        }
        .fc-daygrid-day-events {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .fc-h-event {
            background: none;
            border: none;
            &.open::after {
              content: "○";
              font-size: clamp(12px, 5vw, 50px);
              color: #ff0d0d;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &.close::after {
              content: "×";
              font-size: clamp(12px, 5vw, 50px);
              color: #ff0d0d;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .ui.inverted.table.schedule-list {
        margin-bottom: 3em;
      }

      .ui.inverted.table.schedule-list tbody tr td.selectable:hover,
      .ui.selectable.inverted.table tbody tr:hover {
        background-color: inherit !important;
      }

      .ui.inverted.table.schedule-list tbody tr {
        &.on {
          background-color: #2b5c8e !important;
        }
        &:hover {
          background-color: #2b5c8e !important;
        }
        .pointer {
          cursor: pointer;
        }
      }
    }
  }
  /* ニュース */
  &.website-news-list {
    section {
      display: grid;
      row-gap: 20px;
      grid-template-columns: 100%;
      h4 {
        margin: 0 !important;
        span {
          font-size: 12px;
          font-weight: normal;
          &:before {
            content: "";
            padding-left: 8px;
          }
          &.secret {
            background-color: #2b5c8e;
            margin-left: 1em;
            padding: 0.2em 0.5em;
            &:before {
              padding-left: 0;
            }
          }
        }
      }
      p {
        margin: 0;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  /* ニュース詳細 */
  &.website-news-edit {
    .inner {
      max-width: 1280px;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 20px;
      .content-header {
        justify-content: end;
        margin: 1em;
      }
      .button-group {
        align-items: center;
        button {
          margin-left: 2em;
        }
        label {
          font-size: 0.8em;
        }
      }
      .eyecatch {
        .ui.container {
          padding: 0 1em;
          p {
            font-size: 100%;
          }
        }
      }
      .hashtag-wrap {
        .label {
          color: #676767 !important;
          margin: 2px;
          .icon {
            color: #aaaaaa !important;
          }
        }
        .scrollable-container {
          height: 160px;
          overflow-y: auto;
          padding: 0;
          background: transparent;
          border: none;
        }
      }
    }
  }
  /* QA */
  &.website-qa {
    .inner {
      padding-bottom: 50vh;
      section {
        position: relative;
        width: 100%;
        &.on {
          border: 4px solid #69672f;
        }
        .reset {
          position: fixed;
          bottom: 8px;
          right: 1em;
          border-radius: 9999px;
          height: 50px;
          width: 50px;
          font-size: 2em;
          padding: 0;
          z-index: 999;
        }
      }
      .qaform {
        display: grid;
        row-gap: 10px;
        flex-wrap: wrap;
        z-index: 99;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #3f464c;
        padding: 1em 0;
        width: 80%;
        max-width: 1000px;
        box-shadow: 0 0 11px #00000050;
        padding: 1em 2em;
        border-radius: 10px;
        .row {
          .column {
            display: grid;
            row-gap: 10px;
            button {
              width: fit-content;
            }
          }
        }
      }
    }
  }
  /* 口コミ */
  &.website-review {
    .inner {
      padding-bottom: 55vh;
      section {
        position: relative;
        width: 100%;
        &.on {
          border: 4px solid #69672f;
        }
        .reset {
          position: fixed;
          bottom: 8px;
          right: 1em;
          border-radius: 9999px;
          height: 50px;
          width: 50px;
          font-size: 2em;
          padding: 0;
          z-index: 999;
        }
      }
      .reviewForm {
        display: grid;
        row-gap: 10px;
        flex-wrap: wrap;
        z-index: 99;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 55vh;
        overflow-y: scroll;
        background: #3f464c;
        padding: 1em 0;
        width: 80%;
        max-width: 1000px;
        box-shadow: 0 0 11px #00000050;
        padding: 1em 2em;
        border-radius: 10px;
        .row {
          .column {
            display: grid;
            row-gap: 10px;
            button {
              width: fit-content;
            }
            &.hashtag {
              display: inline-block;
              i {
                color: #aaa !important;
              }
            }
            &.hashtag-master .segment {
              background-color: transparent;
              border: none;
              box-shadow: none;
              margin-top: 0;
              .label {
                color: #676767 !important;
                margin: 2px;
              }
            }
          }
        }
      }
    }
  }
  /* よくある質問一覧 */
  &.website-faq-list {
    .qa-wrap {
      display: none;
      &.open {
        display: block;
      }
    }
    .input {
      display: none;
      &.open {
        display: block;
      }
    }
    .button-wrap {
      padding-top: 12px;
      text-align: end;
      .faq-plus {
        height: 35px;
        width: 80px;
        font-size: 1em;
        padding: 0;
      }
    }
    .category-plus {
      border-radius: 9999px;
      height: 50px;
      width: 50px;
      font-size: 2em;
      padding: 0;
      margin-top: 10px;
    }
  }
  /* よくある質問詳細 */
  &.website-faq-edit {
    .inner {
      max-width: 1280px;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 20px;
      .content-header {
        justify-content: end;
        margin: 1em;
      }
    }
  }
  /* 写真/動画 */
  &.website-visual {
    .inner {
      max-width: 1280px;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 20px;
      .content-header {
        justify-content: end;
        margin: 1em;
      }
      .scroll {
        overflow-y: auto;
        height: 45vh;
      }
      width: 95%;
      padding: 20px;
      img,
      video {
        cursor: pointer;
      }
      .media-url {
        overflow-wrap: break-word;
      }
      .media-wrap {
        width: 50% !important;
      }
      .hashtag-wrap {
        .label {
          color: #676767 !important;
          margin: 2px;
          .icon {
            color: #aaaaaa !important;
          }
        }
        .button-group {
          margin-bottom: 5px;
          button {
            margin: 0;
          }
        }
        .scrollable-container {
          height: 160px;
          overflow-y: auto;
          padding: 0;
          background: transparent;
          border: none;
        }
      }
    }
  }
  /* キャンペーン一覧 */
  &.website-campaign-list {
    .inner {
      max-width: 1280px;
      table {
        color: #fff !important;
      }
    }
  }
  /* キャンペーン詳細 */
  &.website-campaign-edit {
    .inner {
      max-width: 1280px;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 20px;
      .content-header {
        justify-content: end;
        margin: 1em;
      }
      .studioList {
        background-color: #40464b;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        padding: 5px;
        .checkItem {
          background-color: #6f787e;
          margin: 2px;
          padding: 9px;
          &.on {
            background-color: #2185d0;
          }
        }
      }
      .priceArea {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto 5% 5%;
        gap: 5px;
        align-items: center;
        margin-bottom: 5px;
        .addRemoveBtn {
          i {
            display: block;
            margin: -3px auto 0 auto;
          }
        }
      }
    }
  }
}
