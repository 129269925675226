/* A4 サイズ指定 */
@page {
  size: A4;
  margin: 20mm;
}

.management-order-print-list {
  /* 全体の印刷スタイル */
  #a4 {
    background-color: #fff !important;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1em;
    padding: 2em;
    align-items: center;
    border: 1px solid #00000010;
    box-sizing: border-box;
    * {
      font-family: 見出ゴMB1, "Noto Sans JP", Poppins,
        "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
      box-sizing: border-box;
    }
    ul {
      border: 2px dashed #555;
      border-radius: 15px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: auto auto;
      page-break-inside: avoid; /* 中途半端な分割を防ぐ */
      &.done {
        display: none;
      }
      li {
        color: #000 !important;
        padding: 0.25em;
        &:nth-child(3) {
          grid-column: span 2;
        }
        &:nth-child(4):before {
          content: "〒";
        }
        &:nth-child(8) {
          grid-column: span 2;
          font-size: 21px;
          word-break: auto-phrase;
          line-height: 1.5;
        }
      }
    }

    /* 印刷時に不要な要素を非表示 */
    @media print {
      .no-print {
        display: none;
      }
      .cell {
        white-space: pre-wrap; /* 印刷時には改行を反映 */
      }

      /* ヘッダー・フッターを調整 */
      header,
      footer {
        position: fixed;
        width: 100%;
        text-align: center;
      }

      header {
        top: 0;
      }

      footer {
        bottom: 0;
      }
    }
  }
}
