body {
  /* 発表会参加者一覧 */
  &.management-recital-2025-customer-list {
    .inner {
      width: 95%;
      max-width: unset;
      .input {
        i {
          right: 1.5em !important;
        }
      }
      .filter-toggle {
        margin-top: 10px;
        cursor: pointer;
        p {
          font-size: 12px;
          font-weight: bold;
        }
      }
      .filter-area {
        padding: 0px 24px;
        .filter-content {
          p {
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 10px;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              font-weight: 700;
              cursor: pointer;
              list-style: none;
              font-size: 12px;
              padding: 5px 9px;
              background-color: #7e7e7e;
              border-radius: 5px;
              text-align: center;
              transition: background-color 0.3s;
              margin-bottom: 5px;
              margin: 0.25em 0.25em 0 0;
              &.on {
                background-color: #4c8fd2;
              }
            }
          }
        }
      }
      .customer-header {
        background-color: #7b7b7b;
        cursor: pointer;
        .cell {
          font-weight: bold;
          i {
            height: auto;
          }
        }
      }
      .customer-grid {
        display: grid;
        grid-template-columns: 4% 5% 8% 9% 19% 7% 9% 10% 7% 6% 8% auto;
        margin: 0;
        transition-duration: 0.25s;
        height: auto; /* 高さを自動調整 */
        &:hover {
          background-color: #00000010;
        }
        .cell {
          list-style: none;
          font-size: 11px;
          padding: 0.5em; /* パディングを追加して余白を確保 */
          overflow: hidden;
          border-bottom: 1px solid #ddd;
          display: flex; /* フレックスボックスに変更 */
          align-items: center;
          white-space: pre-wrap; /* 改行を有効にする */
          word-wrap: break-word; /* 長い単語を折り返す */
          a {
            text-decoration: underline;
            font-size: 11px;
          }
        }
      }
    }
  }
}
