.management-recital-2025-seat {
  .inner {
    padding: 20px;
    width: 100%;
    margin: 0;
    max-width: none;
    h2,
    li,
    p,
    span {
      color: #000 !important;
    }
  }
  h3 {
    font-size: 35px;
    text-align: center;
    margin-bottom: 50px;
    font-weight: bold;
  }
  h2 {
    color: #000 !important;
    font-size: 25px;
    text-align: center;
    padding: 50px 0;
    font-weight: bold;
  }

  .section {
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-bottom: 50px;
    .seat {
      $gapWidth: 50px;
      display: grid;
      grid-template-columns: 50% calc(50% - #{$gapWidth});
      gap: $gapWidth;
      .seatList {
        width: calc(100% - 20px);
        margin: 0 auto;
        ul {
          display: grid;
          grid-template-columns: 3em auto auto auto;
          overflow-y: auto;
          height: 100svh;
          background-color: #fff;
          li {
            border-bottom: 1px solid #00000050;
            padding: 0.5em;
            box-sizing: border-box;
            font-size: 10px;
            list-style: none;
          }
        }
      }
    }
  }

  //  SVG
  .hall-line {
    stroke: #000;
    fill: none;
  }
  .red,
  .type_1 {
    fill: red;
  }
  .type_1 {
    fill: #e6194b;
  }
  .type_2 {
    fill: #3cb44b;
  }
  .type_3 {
    fill: #ffe119;
  }
  .type_4 {
    fill: #4363d8;
  }
  .type_5 {
    fill: #f58231;
  }
  .type_6 {
    fill: #911eb4;
  }
  .type_7 {
    fill: #46f0f0;
  }
  .type_8 {
    fill: #f032e6;
  }
  .type_9 {
    fill: #bcf60c;
  }
  .type_10 {
    fill: #fabebe;
  }
  .type_11 {
    fill: #008080;
  }
  .type_12 {
    fill: #e6beff;
  }
  .type_13 {
    fill: #9a6324;
  }
  .type_14 {
    fill: #fffac8;
  }
  .type_15 {
    fill: #800000;
  }
  .type_16 {
    fill: #8BC34A;
  }
  .type_17 {
    fill: #2196F3;
  }
  .type_18 {
    fill: #9E9E9E;
  }
  .type_19 {
    fill: #FF5722;
  }
  .type_20 {
    fill: #2fc5b7;
  }
  .type_21 {
    fill: #d83fff;
  }
  .type_22 {
    fill: #ffb53f;
  }
  .type_98 {
    fill: #747474;
  }
  .type_99 {
    fill: #ffffff;
  }
  path.seat-item:hover {
    fill: rgb(0, 106, 255);
    cursor: pointer;
  }
  .seatnumber {
    font-size: 100px;
    background-color: #fff;
    // position: fixed;
    // right: 50%;
    // top: 10vh;
    // box-shadow: 0 0 5px #000;
  }

  //   検証用
  #stkr {
    position: fixed;
    top: -8em;
    left: 0px;
    transition: 0.2s;
    transition-timing-function: ease-out;
    pointer-events: none;
    z-index: 10;
    white-space: nowrap;
    line-height: 1.5;
    font-size: 12px;
    font-weight: bold;
    padding: 0.25em;
    span {
      font-size: 8px;
      font-weight: normal;
    }
  }
  .studio_seat_count_list {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 300px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1px 10px;
    background-color: #fffce8;
    padding: 10px 10px;
    border-radius: 10px;
    box-shadow: 0 0 5px #00000050;
    scale: 0.5;
    transform-origin: 100% 100%;
    transition-duration: 0.25s;
    &:hover {
      scale: 1;
    }
    li {
      display: grid;
      grid-template-columns: 5em auto auto;
      &.full {
        grid-column: span 2;
        background-color: #ff004450;
        padding: 0.5em;
      }
      p,
      span {
        font-size: 10px;
        font-weight: bold;
      }
      span {
        &:before {
          content: "(";
        }
        &:after {
          content: ")";
        }
      }
    }
  }

  .ov {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    background-color: #00000050;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      grid-column: span 2;
    }
    .ovinner {
      width: 80%;
      height: 80svh;
      background-color: #ffffff;
      display: grid;
      grid-template-columns: 40% 40%;
      align-items: center;
      justify-content: center;
      gap: 5%;
      ul {
        li {
          border-bottom: 1px solid #555;
        }
      }
    }
  }
}
